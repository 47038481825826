import {
  Grid,
  Alert,
  AlertTitle,
  Typography,
} from "@mui/material";
import * as Sentry from "@sentry/nextjs";
import React, { useEffect, useState } from "react";
import defaultLanguage from "../translations/defaultLanguage";
import getTranslation from "../translations/getTranslation";
import { Label } from "../translations/Label";
import { Lang } from "../translations/Lang";
import Link from '../Link';
import { useRouter } from 'next/router';

function Error() {
  const [language, setLanguage] = useState(defaultLanguage);
  const { asPath } = useRouter();

  useEffect(() => {
    const lang = navigator.language;
    if (lang === "de-DE" || lang === "de-CH" || lang === "de") {
      setLanguage(Lang.de);
    }
    if (lang === "fr-FR" || lang === "fr" || lang === "fr-CH") {
      setLanguage(Lang.fr);
    }
    if (lang === "it-IT" || lang === "it" || lang === "it-CH") {
      setLanguage(Lang.it);
    }
    if (lang === "en-US" || lang === "en" || lang === "en-GB") {
      setLanguage(Lang.en);
    }
  }, []);

  useEffect(() => {
    Sentry.captureMessage('Error component shown at: ' + asPath);
  }, [asPath])

  return (
    <Grid sx={{ mt: 1 }} item xs={12} spacing={3} container justifyContent="center">
      <Grid item xs={12}>
        <Alert severity='error' icon={false}>
          <AlertTitle>
            {getTranslation(Label.ErrorAlertTitle, language)}
          </AlertTitle>
          {getTranslation(Label.ErrorAlertText, language)}
        </Alert>
      </Grid>
      <Grid item justifyItems="center">
        <Typography variant="h3">
          <Link href='tel:0844834844'>0844 834 844</Link>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Error;
