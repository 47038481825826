export enum Label {
  firstName = "firstName",
  lastName = "lastName",
  birthday = "birthday",
  gender = "gender",
  email = "email",
  phone = "phone",
  male = "male",
  female = "female",
  diverse = "diverse",
  requiredFirstName = "requiredFirstName",
  requiredLastName = "requiredLastName",
  birthDateFormat = "birthDateFormat",
  invalidDate = "invalidDate",
  minDateError = "minDateError",
  maxDateError = "maxDateError",
  invalidEmail = "invalidEmail",
  FAM = "FAM",
  KFAM = "KFAM",
  PAAR = "PAAR",
  EZ = "EZ",
  CON = "CON",
  addMissingBirthYears = "addMissingBirthYears",
  save = "save",
  back = "back",
  cancel = "cancel",
  deletePerson = "deletePerson",
  errorDataLoadingTitle = "errorDataLoadingTitle",
  errorDataLoadingText = "errorDataLoadingText",
  noMorePersonsCanBeAdded = "noMorePersonsCanBeAdded",
  max1AdultAllowed = "max1AdultAllowed",
  max2AdultsAllowed = "max2AdultsAllowed",
  saveSuccess = "saveSuccess",
  saveError = "saveError",
  savePersonError = "savePersonError",
  deletePersonSuccess = "deletePersonSuccess",
  deletePersonError = "deletePersonError",
  addPersonSuccess = "addPersonSuccess",
  addPersonError = "deletePersonError",
  noChildrenAlertTitle = "noChildrenAlertTitle",
  addChildLinkText = "addChildLinkText",
  noChildrenOrChooseText = "noChildrenOrChooseText",
  noChildrenOptionLabel = "noChildrenOptionLabel",
  noSecondAdultAlertTitle = "noSecondAdultAlertTitle",
  addPersonLinkText = "addPersonLinkText",
  noSecondAdultOrChooseText = "noSecondAdultOrChooseText",
  noSecondAdultOptionLabel = "noSecondAdultOptionLabel",
  scanQRCodeText = "scanQRCodeText",
  enterYourID = "enterYourID",
  idNotFoundTitle = "idNotFoundTitle",
  idNotFoundText = "idNotFoundText",
  openMyDataButtonLabel = "openMyDataButtonLabel",
  language = "language",
  de = "de",
  fr = "fr",
  it = "it",
  en = "en",
  patronNumber = "patronNumber",
  missingBirthYear = "missingBirthYear",
  addPerson = "addPerson",
  introText = "introText",
  needHelpTitle = "needHelpTitle",
  needHelpText = "needHelpText",
  allValidTitle = "allValidTitle",
  allValidText = "allValidText",
  ErrorAlertTitle = "ErrorAlertTitle",
  ErrorAlertText = "ErrorAlertText",
}