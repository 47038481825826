import { Label } from "./Label";

const translations = {
  [Label.firstName]: {
    de: "Vorname",
    en: "First name",
    fr: "Prénom",
    it: "Nome",
  },
  [Label.lastName]: {
    de: "Name",
    en: "Family name",
    fr: "Nom",
    it: "Cognome",
  },
  [Label.birthday]: {
    de: "Geburtsdatum",
    en: "Date of birth",
    fr: "Date de naissance",
    it: "Data di nascita",
  },
  [Label.gender]: {
    de: "Geschlecht",
    en: "Gender",
    fr: "Genre",
    it: "Sesso",
  },
  [Label.email]: {
    de: "E-Mail",
    en: "E-mail",
    fr: "E-mail",
    it: "E-mail",
  },
  [Label.phone]: {
    de: "Telefon",
    en: "Phone number",
    fr: "Numéro de téléphone",
    it: "Telefono",
  },
  [Label.male]: {
    de: "Männlich",
    en: "Male",
    fr: "Homme",
    it: "Uomo",
  },
  [Label.female]: {
    de: "Weiblich",
    en: "Female",
    fr: "Femme",
    it: "Donna",
  },
  [Label.diverse]: {
    de: "Divers",
    en: "Diverse",
    fr: "Autre",
    it: "Altro",
  },
  [Label.requiredFirstName]: {
    de: "Bitte geben Sie den Vornamen an.",
    en: "Please enter the first name.",
    fr: "Prière de saisir votre prénom.",
    it: "Inserisca il nome.",
  },
  [Label.requiredLastName]: {
    de: "Bitte geben Sie den Nachnamen an.",
    en: "Please enter the family name.",
    fr: "Prière de saisir votre nom.",
    it: "Inserisca il cognome.",
  },
  [Label.birthDateFormat]: {
    de: "TT.MM.JJJJ",
    en: "DD.MM.YYYY",
    fr: "JJ.MM.AAAA",
    it: "GG.MM.AAAA",
  },
  [Label.invalidDate]: {
    de: "Das Datum ist nicht gültig. Format: TT.MM.JJJJ",
    en: "Must be a valid date. Format: DD.MM.YYYY",
    fr: "La date doit être valide. Format : JJ.MM.AAAA",
    it: "La data non è valida. Formato: GG.MM.AAAA",
  },
  [Label.minDateError]: {
    de: "Bitte geben Sie ein gültiges Geburtsdatum ein. Format: TT.MM.JJJJ",
    en: "Please enter a valid date of birth. Format: DD.MM.YYYY",
    fr: "Veuillez saisir une date de naissance valide. Format : JJ.MM.AAAA",
    it: "Inserisca una data di nascita valida. Formato: GG.MM.AAAA",
  },
  [Label.maxDateError]: {
    de: "Geburtsdatum liegt in der Zukunft (TT.MM.JJJJ).",
    en: "The date of birth entered is in the future (DD.MM.YYYY).",
    fr: "La date de naissance ne peut pas se situer dans le futur (JJ.MM.AAAA).",
    it: "La data di nascita non può essere nel futuro (GG.MM.AAAA).",
  },
  [Label.invalidEmail]: {
    de: "Bitte geben Sie eine gültige E-Mail ein.",
    en: "Please enter a valid e-mail address.",
    fr: "Veuillez saisir une adresse e-mail valide.",
    it: "Inserisca un indirizzo e-mail valido.",
  },
  [Label.FAM]: {
    de: "Familiengönnerschaft",
    en: "Family patronage",
    fr: "Affiliation famille",
    it: "Tesseramento famiglia",
  },
  [Label.KFAM]: {
    de: "Klein-Familiengönnerschaft",
    en: "One-Parent Family patronage",
    fr: "Affiliation famille monoparentale",
    it: "Tesseramento famiglia monoparentale",
  },
  [Label.PAAR]: {
    de: "Paargönnerschaft",
    en: "Couple patronage",
    fr: "Affiliation couple",
    it: "Tesseramento coppia",
  },
  [Label.EZ]: {
    de: "Einzelgönnerschaft",
    en: "Individual patronage",
    fr: "Affiliation personne seule",
    it: "Tesseramento persona singola",
  },
  [Label.CON]: {
    de: "Landwirt",
    en: "Farmer",
    fr: "Agriculteurs",
    it: "Agricoltura",
  },
  [Label.addMissingBirthYears]: {
    de: "Bitte fügen Sie die fehlenden Geburtsdaten hinzu.",
    en: "Please add the missing dates of birth.",
    fr: "Veuillez ajouter les dates de naissance manquantes.",
    it: "Aggiunga le date di nascita mancanti.",
  },
  [Label.save]: {
    de: "Speichern",
    en: "Save",
    fr: "Sauvegarder",
    it: "Salvare",
  },
  [Label.back]: {
    de: "Zurück",
    en: "Back",
    fr: "Retour",
    it: "Indietro",
  },
  [Label.cancel]: {
    de: "Abbrechen",
    en: "Cancel",
    fr: "Annuler",
    it: "Annullare",
  },
  [Label.deletePerson]: {
    de: "Person löschen",
    en: "Delete person",
    fr: "Supprimer le profil de cette personne",
    it: "Eliminare la persona",
  },
  [Label.errorDataLoadingTitle]: {
    de: "Error",
    en: "Error",
    fr: "Erreur",
    it: "Errore",
  },
  [Label.errorDataLoadingText]: {
    de: "Ihre Daten konnten nicht geladen werden.",
    en: "Your data could not be loaded.",
    fr: "Vos données n'ont pas pu être téléchargées.",
    it: "Impossibile caricare i suoi dati.",
  },
  [Label.noMorePersonsCanBeAdded]: {
    de: "Es können keine weiteren Personen hinzugefügt werden.",
    en: "No more people can be added.",
    fr: "Aucune autre personne ne peut être ajoutée.",
    it: "Non è possibile aggiungere altre persone.",
  },
  [Label.max1AdultAllowed]: {
    de: "Es kann maximal eine erwachsene Person hinzugefügt werden.",
    en: "A maximum of one adult can be added.",
    fr: "Il est possible d'ajouter une personne adulte au maximum.",
    it: "È possibile aggiungere un massimo di un adulto.",
  },
  [Label.max2AdultsAllowed]: {
    de: "Es können maximal zwei erwachsene Personen hinzugefügt werden.",
    en: "A maximum of two adults can be added.",
    fr: "Deux personnes adultes peuvent être ajoutées au maximum.",
    it: "È possibile aggiungere un massimo di due adulti.",
  },
  [Label.saveSuccess]: {
    de: "Eingaben erfolgreich gespeichert.",
    en: "Entries saved successfully.",
    fr: "Données enregistrées avec succès.",
    it: "Dati inseriti salvati con successo.",
  },
  [Label.saveError]: {
    de: "Eingaben konnten nicht gespeichert werden.",
    en: "Entries could not be saved.",
    fr: "Les données n’ont pas pu être enregistrées.",
    it: "Impossibile salvare i dati inseriti.",
  },
  [Label.savePersonError]: {
    de: "Person konnte nicht gespeichert werden.",
    en: "Person could not be saved.",
    fr: "L’enregistrement des données concernant cette personne a échoué.",
    it: "Impossibile registrare la persona.",
  },
  [Label.deletePersonSuccess]: {
    de: "Person erfolgreich gelöscht.",
    en: "Person deleted successfully.",
    fr: "Les données concernant cette personne ont été supprimées avec succès.",
    it: "Persona eliminata con successo.",
  },
  [Label.deletePersonError]: {
    de: "Person konnte nicht gelöscht werden.",
    en: "Person could not be deleted.",
    fr: "La suppression des données concernant la personne a échoué.",
    it: "Impossibile eliminare la persona.",
  },
  [Label.addPersonSuccess]: {
    de: "Person erfolgreich hinzugefügt.",
    en: "Person added successfully.",
    fr: "Les données concernant cette personne ont été ajoutées avec succès.",
    it: "Persona aggiunta con successo.",
  },
  [Label.addPersonError]: {
    de: "Person konnte nicht erstellt werden.",
    en: "Person could not be created.",
    fr: "La création du profil de cette personne a échoué.",
    it: "Impossibile creare la persona.",
  },
  [Label.noChildrenAlertTitle]: {
    de: "Es sind keine Kinder hinterlegt.",
    en: "There are no children deposited.",
    fr: "Aucun profil enfant enregistré.",
    it: "Non ci sono figli registrati.",
  },
  [Label.addChildLinkText]: {
    de: "Fügen Sie ein minderjähriges Kind hinzu",
    en: "Add a minor child",
    fr: "Ajouter un profil enfant",
    it: "Aggiunga un figlio minorenne",
  },
  [Label.noChildrenOrChooseText]: {
    de: 'oder wählen Sie "Keine minderjährigen Kinder" an.',
    en: 'or select "No underage children".',
    fr: 'ou sélectionnez "Pas d\'enfants mineurs".',
    it: 'oppure selezioni "Nessun figlio minorenne".',
  },
  [Label.noChildrenOptionLabel]: {
    de: "Keine minderjährigen Kinder",
    en: "No minor children",
    fr: "Pas d'enfants mineurs",
    it: "Nessun figlio minorenne",
  },
  [Label.noSecondAdultAlertTitle]: {
    de: "Es ist nur eine erwachsene Person hinterlegt.",
    en: "Only one adult has been registered.",
    fr: "Il n’y a qu’un seul profil adulte enregistré.",
    it: "È registrato solo un adulto.",
  },
  [Label.addPersonLinkText]: {
    de: "Fügen Sie eine weitere Person hinzu",
    en: "Add another person",
    fr: "Ajouter une autre personne",
    it: "Aggiunga un'altra persona",
  },
  [Label.noSecondAdultOrChooseText]: {
    de: 'oder wählen Sie "Nur eine erwachsene Person" an.',
    en: 'or select "One adult only".',
    fr: 'ou sélectionnez "Un seul adulte".',
    it: 'oppure selezioni "Solo un adulto".',
  },
  [Label.noSecondAdultOptionLabel]: {
    de: "Nur eine erwachsene Person",
    en: "One adult only",
    fr: "Un seul adulte",
    it: "Solo un adulto",
  },
  [Label.scanQRCodeText]: {
    de: "Scannen Sie Ihren QR Code ein, um auf Ihre Daten zu gelangen",
    en: "Scan your QR code to access your data",
    fr: "Scannez votre code QR pour accéder à vos données",
    it: "Scansioni il suo codice QR per accedere ai suoi dati",
  },
  [Label.enterYourID]: {
    de: "oder geben Sie hier Ihre ID ein:",
    en: "or enter your ID here:",
    fr: "ou entrez votre identifiant ici:",
    it: "oppure inserisca qui il suo ID:",
  },
  [Label.idNotFoundTitle]: {
    de: "Wir konnten die eingegebene ID nicht finden.",
    en: "We were unable to find the ID entered.",
    fr: "Nous n'avons pas pu trouver l'identifiant qui a été saisi.",
    it: "Non siamo riusciti a trovare l'ID inserito.",
  },
  [Label.idNotFoundText]: {
    de: "Bitte prüfen Sie, ob Sie Ihre ID richtig eingegeben haben.",
    en: "Please check that you have entered your ID correctly.",
    fr: "Veuillez vérifier si vous avez correctement saisi votre identifiant.",
    it: "Verifichi d’aver inserito correttamente il suo ID.",
  },
  [Label.openMyDataButtonLabel]: {
    de: "Meine Daten öffnen",
    en: "Open my data",
    fr: "Accéder à mes données",
    it: "Aprire i miei dati",
  },
  [Label.language]: {
    de: "Sprache",
    en: "Language",
    fr: "Langue",
    it: "Lingua",
  },
  [Label.de]: {
    de: "Deutsch",
    en: "German",
    fr: "Allemand",
    it: "Tedesco",
  },
  [Label.fr]: {
    de: "Französisch",
    en: "French",
    fr: "Français",
    it: "Francese",
  },
  [Label.it]: {
    de: "Italienisch",
    en: "Italian",
    fr: "Italien",
    it: "Italiano",
  },
  [Label.en]: {
    de: "Englisch",
    en: "English",
    fr: "Anglais",
    it: "Inglese",
  },
  [Label.patronNumber]: {
    de: "Gönner-Nr.",
    en: "Patronage number",
    fr: "Numéro lié au compte de donatrice ou de donateur",
    it: "Numero di sostenitrice/sostenitore",
  },
  [Label.missingBirthYear]: {
    de: "Fehlendes Geburtsdatum",
    en: "Missing date of birth",
    fr: "Date de naissance manquante",
    it: "Data di nascita mancante",
  },
  [Label.addPerson]: {
    de: "Person hinzufügen",
    en: "Add person",
    fr: "Ajouter une personne",
    it: "Aggiungere una persona",
  },
  [Label.introText]: {
    de: "Bitte ergänzen Sie nachfolgend die Daten.",
    en: "Please complete the data below.",
    fr: "Veuillez compléter les données ci-dessous.",
    it: "Completi i dati seguenti.",
  },
  [Label.needHelpTitle]: {
    de: "Brauchen Sie Hilfe?",
    en: "Do you need help?",
    fr: "Besoin d'aide ?",
    it: "Ha bisogno d’aiuto?",
  },
  [Label.needHelpText]: {
    de: "Haben Sie eine Frage oder ein Problem? Sie erreichen uns unter der Nummer:",
    en: "Do you have a question or a problem? You can reach us at:",
    fr: "Une question ou un problème ? Joignez-nous au :",
    it: "Ha una domanda o un problema? Ci contatti al numero:",
  },
  [Label.allValidTitle]: {
    de: "Vielen Dank für Ihre Mithilfe!",
    en: "Many thanks for your help!",
    fr: "Merci pour votre aide !",
    it: "Grazie per la sua collaborazione!",
  },
  [Label.allValidText]: {
    de: "Ihre Daten wurden gespeichert, das Fenster kann geschlossen werden.",
    en: "Your data has been saved, the window can now be closed.",
    fr: "Vos données ont été enregistrées avec succès. Vous pouvez désormais fermer la fenêtre.",
    it: "I suoi dati sono stati salvati, la finestra può essere chiusa.",
  },
  [Label.ErrorAlertTitle]: {
    de: "Error",
    en: "Error",
    fr: "Erreur",
    it: "Errore",
  },
  [Label.ErrorAlertText]: {
    de: "Ihre Daten konnten nicht geladen werden. Bitte kontaktieren Sie uns unter:",
    en: "Your data could not be loaded. Please contact us at:",
    fr: "Vos données n'ont pas pu être téléchargées. Veuillez s'il vous plaît nous contacter au:",
    it: "Impossibile caricare i suoi dati. Per favore contattateci sul:",
  },
};

export default translations;
